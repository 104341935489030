.table-row {
  color: rgb(155, 155, 155);
}
.table-row td {
  text-align: center;
  padding: 10px;
}
.table-row td img {
  width: 100px;
}
.table-row-name {
  font-size: 16px;
  font-weight: bold;
  color: rgb(56, 56, 56);
}
.arrow {
  display: flex;
  justify-content: center;
  gap: 5px;
  font-style: normal;
  cursor: pointer;
  color: rgb(56, 56, 56);
}
