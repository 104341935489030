.product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 1130px;
  margin: 0 auto;
}

.products {
  font-style: italic;
}
.products p {
  color: rgb(157, 164, 170);
  margin: 0;
}
.products h2 {
  color: rgb(56, 56, 56);
  margin: 0;
  margin-bottom: 20px;
}
