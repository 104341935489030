.checkout {
  display: flex;
  flex-direction: column;
  font-style: italic;
  color: rgb(56, 56, 56);
  margin-bottom: 50px;
}
.checkout h3 {
  font-size: 24px;
  font-weight: 500;
  margin-top: 50px;
}
.checkout-top {
  height: 200px;
  background-color: rgb(248, 249, 250);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  font-style: italic;
}
.checkout-top h2 {
  font-size: 32px;
  font-weight: 500;
  color: rgb(56, 56, 56);
}
.checkout-top p {
  color: rgb(155, 155, 155);
}
.checkout-top p span {
  color: rgb(56, 56, 56);
  font-weight: 500;
}
.checkout-bottom {
  display: flex;
  gap: 30px;
}
.checkout-form {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: rgb(155, 155, 155);
}
.checkout-form input {
  padding: 10px 20px;
  border: 1px solid rgb(155, 155, 155);
  color: rgb(155, 155, 155);
  outline: none;
  margin-bottom: 10px;
}
.checkout-form button {
  align-self: flex-start;
  padding: 10px 20px;
  background-color: rgb(56, 56, 56);
  color: rgb(214, 222, 228);
  font-size: 16px;
  border: 0;
  font-style: italic;
}
.total {
  align-self: flex-start;
  width: 30%;
  display: flex;
  flex-direction: column;
  background-color: rgb(248, 249, 250);
  padding: 30px;
}
.total h3 {
  margin: 0;
}
.total-number {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.total-number .total-number-total {
  font-weight: bold;
}
.total-number .total-number-number {
  font-size: 20px;
}
