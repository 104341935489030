.footer {
  background-color: black;
  color: white;
}
.footer-center {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 30px 10px 50px 10px;
}
