.shop-page {
  display: flex;
  flex-direction: column;
}
.shop-page-banner {
  height: 200px;
  background-color: rgb(248, 249, 250);
  color: rgb(56, 56, 56);
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  align-items: center;
  font-style: italic;
}
.shop-page-banner h2 {
  font-size: 32px;
  font-weight: 600;
}
.shop-page-banner p {
  color: rgb(157, 164, 170);
}
.shop-page-content {
  display: flex;
  font-style: italic;
  gap: 30px;
  margin: 50px 0;
}
.content-left {
  color: rgb(155, 155, 155);
  width: 20%;
}
.content-left h3 {
  color: rgb(56, 56, 56);
}
.content-left h4 {
  background-color: rgb(248, 249, 250);
  color: rgb(56, 56, 56);
  padding: 10px;
}
.content-left p {
  padding-left: 10px;
  cursor: pointer;
}
.content-left p:hover {
  color: rgb(227, 178, 86);
}
.content-left p.active {
  color: rgb(227, 178, 86);
}
.content-left .apple-background {
  background-color: rgb(56, 56, 56);
  color: rgb(214, 222, 228);
}
.content-right {
  width: 80%;
}
.content-right-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.content-right-search .search {
  padding: 10px 20px;
  border: 1px solid rgb(155, 155, 155);
  color: rgb(157, 164, 170);
  outline: none;
}
.page-btn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  height: 60px;
}
.page-btn p {
  margin: 0;
  color: rgb(155, 155, 155);
}
.page-btn span {
  padding: 2px 10px;
  border: 1px solid rgb(56, 56, 56);
  color: rgb(155, 155, 155);
}
.page-btn .page-number {
  background-color: rgb(56, 56, 56);
  color: white;
}
