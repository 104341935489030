.product-item {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-style: italic;
}
.product-item img {
  width: 100%;
  margin-bottom: 20px;
  transition: opacity 0.5s;
}
.product-item img:hover {
  opacity: 50%;
}

.product-item p {
  text-align: center;
  margin: 0;
}
.product-item-name {
  font-size: 16px;
  font-weight: bold;
}
.product-item-price {
  color: rgb(155, 155, 155);
}
