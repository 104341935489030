.sign-up {
  position: relative;
  height: 800px;
  background-color: rgb(244, 245, 249);
  font-style: italic;
}
.sign-up img {
  width: 100%;
  height: 600px;
  object-fit: cover;
}
.center-box {
  padding: 50px 20px;
  position: absolute;
  background-color: white;
  width: 500px;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.186);
}
.center-box h1 {
  font-weight: 400;
  color: rgb(155, 155, 155);
}
.center-box form {
  display: flex;
  flex-direction: column;
}
.center-box form input {
  padding: 20px 20px;
  border: 1px solid rgb(214, 222, 228);
  color: rgb(155, 155, 155);
  font-size: 16px;
  width: 350px;
  outline: none;
}
.center-box form button {
  padding: 20px 20px;
  margin-top: 20px;
  background-color: rgb(56, 56, 56);
  color: rgb(214, 222, 228);
  border: 0;
}
.center-box p {
  color: rgb(155, 155, 155);
}
.center-box p span {
  color: rgb(116, 151, 225);
  cursor: pointer;
}
