.background {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.603);
  height: 100%;
  width: 100%;
  z-index: 10;
  opacity: 100%;
  animation-name: show;
  animation-duration: 0.5s;
}
@keyframes show {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}
.centerbox {
  width: 1200px;
  background-color: white;
  margin: 0 auto;
  margin-top: 100px;
  display: flex;
  padding: 10px;
  font-style: italic;
  position: relative;
  display: flex;
  align-items: flex-start;
}
.centerbox img {
  width: 50%;
}
.centerbox button {
  padding: 10px 30px;
  background-color: rgb(56, 56, 56);
  color: rgb(214, 222, 228);
  font-style: italic;
  border: 0;
  font-size: 16px;
  margin-top: 30px;
  cursor: pointer;
  transition: background-color 0.5s;
}
.centerbox button:hover {
  background-color: black;
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-style: normal;
  font-weight: bold;
  cursor: pointer;
}
.infomation {
  width: 50%;
  padding: 50px;
}

.infomation .infomation-name {
  color: rgb(56, 56, 56);
  font-size: 32px;
  font-weight: 600;
  margin: 0;
}

.infomation .infomation-price {
  color: rgb(155, 155, 155);
  margin: 0;
  font-size: 24px;
}

.infomation .infomation-desc {
  color: rgb(157, 164, 170);
}
